import LOWYBG from "../Logo/LogoOnlyWithYellowBg";

export default function MeetTommy() {
    return (
        <section className="relative pt-20 bg-error pb-20 text-white font-bold">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                        <img alt="Tommy Neiman Portait" src="/img/chrome_sUcqK2pGn6.png" width="100%"/>
                    </div>
                    <div className="w-full md:w-8/12 ml-auto mr-auto px-4 text-center ">
                        <LOWYBG bgColor="bg-accent" showTagline={true}/>
                        <h2 className="text-4xl m-4">About the Author, Firefighter Tommy Neiman.</h2>
                        <p className="text-2xl">
                            Tommy 'Firefighter Tom' is a career firefighter/paramedic with over 30 years of
                            expierence in a busy area of south Florida. He and his wife have 3 married children
                            who were homeschooled. In 2003 Tommy was honored as Florida Firefigher of the Year.
                        </p>
                        <p className="text-2xl m-3">
                            See more about Tommy at <a className="text-accent" href="https://www.tommyneiman.com">www.tommyneiman.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
