import LOWYBG from '../Logo/LogoOnlyWithYellowBg';
import './hero.css'
export default function Hero() {
    return (
        <div className="hero-bg bg-secondary mb-6 relative flex content-center items-center justify-center">
            <div className="container relative mx-auto">
                <div className="items-center flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                        <div className="antique-olive">
                            <LOWYBG />
                            <p className="text-white -mt-5 text-xl soft-outline">
                                Learning about God and First Responder work
                                through 'Real' calls!
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
