function FormElements() {
    return (
        <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48 pt-10">
            <div className="w-full lg:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-primary">
                    <div className="flex-auto p-5 lg:p-10">
                        <h4 className="text-2xl font-semibold text-white">
                            Want to get in touch?
                        </h4>
                        <p className="leading-relaxed mt-1 mb-4 text-white">
                            Complete this form and we will get back to you
                            shortly.
                        </p>
                        <div className="relative w-full mb-3 mt-8">
                            <label
                                className="block uppercase text-white text-xs font-bold mb-2"
                                htmlFor="full-name"
                            >
                                Full Name
                            </label>
                            <input
                                type="text"
                                className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Full Name"
                                style={{
                                    transition: 'all 0.15s ease 0s',
                                }}
                                name="fullname"
                                required
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-white text-xs font-bold mb-2"
                                required
                                htmlFor="email"
                            >
                                Email
                            </label>
                            <input
                                type="email"
                                className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Email"
                                style={{
                                    transition: 'all 0.15s ease 0s',
                                }}
                                name="email"
                                required
                            />
                        </div>
                        <div className="relative w-full mb-3">
                            <label
                                className="block uppercase text-white text-xs font-bold mb-2"
                                htmlFor="message"
                            >
                                Message
                            </label>
                            <textarea
                                rows="4"
                                cols="80"
                                className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                                placeholder="Type a message..."
                                name="message"
                                required
                            ></textarea>
                            <input
                                type="hidden"
                                name="_subject"
                                value="New submission! - RookieRescuer.com"
                            />
                            <input
                                type="hidden"
                                name="_autoresponse"
                                value="Thank you for you inquery, we will respond shortly."
                            />
                            <input
                                type="text"
                                name="_honey"
                                style={{
                                    display: 'none',
                                }}
                            />
                            <input
                                type="hidden"
                                name="_template"
                                value="basic"
                            />
                        </div>
                        <div className="text-center mt-6">
                            <button
                                type="submit"
                                className="bg-success text-white active:bg-success text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                style={{
                                    transition: 'all 0.15s ease 0s',
                                }}
                            >
                                Send Message
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function ContactForm() {
    return (
        <form
            action="https://formsubmit.co/ca1f9f59336ea635c797ee8f0940a922"
            method="POST"
        >
            <FormElements />
        </form>
    );
}

export default function Contact() {
    return (
        <section className="relative block py-24 lg:pt-0 bg-warning">
            <div className="container mx-auto px-4">
                <ContactForm />
            </div>
        </section>
    );
}
