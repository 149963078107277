import './footer.css';

export default function Footer() {
    return (
        <footer className="footer relative bg-black pt-8 pb-6 text-white">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap items-center md:justify-between justify-center">
                    <div className="w-full md:w-4/12 px-4 mx-auto text-center">
                        <div className="text-sm text-gray-600 font-semibold py-1">
                            Copyright &copy; {new Date().getFullYear()} Michael
                            Bechtel.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
