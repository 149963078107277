export default function WhatYouGet() {
    return (
        <section className="relative py-20">
            <div className="container mx-auto px-4">
                <div className="items-center flex flex-wrap">
                    <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                        <img
                            alt="Rookie Rescuer Front Cover"
                            className="max-w-full rounded-lg shadow-lg"
                            src="img/rookie-cover.jpg"
                            style={{
                                width: '634px',
                            }}
                        />
                    </div>
                    <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                        <div className="md:pr-12">
                            <canvas
                                id="sales-badge-canvas"
                                height="192px"
                                width="293px"
                            ></canvas>

                            <h3 className="text-3xl font-semibold">
                                Workbook for Kids!
                            </h3>
                            <p className="mt-4 text-lg leading-relaxed text-gray-600">
                                The Rookie Rescuer Workbook is cover priced at
                                $29.95. Through RookieRescuer.com, your price is
                                $15.95. Flat Rate Shipping $2.00.
                            </p>

                            <p className="mt-4 text-lg leading-relaxed text-gray-600 py-3">
                                <a
                                    href="https://5889c8-4.myshopify.com/products/rookie-rescuer"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="button block text-center w-full bg-error py-2 px-4 text-white drop-shadow-md"
                                >
                                    Order Now
                                </a>
                            </p>
                            <ul className="list-none mt-6">
                                <li className="py-2">
                                    <div className="flex items-center">
                                        <div>
                                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-info bg-error mr-3">
                                                <i className="fas fa-shipping-fast"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <h4 className="text-gray-600">
                                                10 or more.....$14.95{' '}
                                                <span className="rounded-full bg-accent text-xs py-1 px-2">
                                                    Free Shipping
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                                <li className="py-2">
                                    <div className="flex items-center">
                                        <div>
                                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-info bg-error mr-3">
                                                <i className="fas fa-box-open"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <h4 className="text-gray-600">
                                                20 or more.....$13.95{' '}
                                                <span className="rounded-full bg-accent text-xs py-1 px-2">
                                                    Free Shipping
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div id="summary"></div>
        </section>
    );
}
