import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function MetaData(props) {
    const [title] = useState(props?.title || 'Rookie Rescuer by Tommy Neiman');
    const [viewport] = useState(
        props?.viewport || 'width=device-width, initial-scale=1'
    );
    const [description] = useState(
        props?.description ||
            'A Christian-based workbook for children about fire and rescue personel.'
    );
    const [ogTitle] = useState(
        props?.ogTitle || 'Rookie Rescuer by Tommy Neiman'
    );
    const [ogType] = useState(props?.ogType || 'website');
    const [author] = useState(props?.author || 'Tommy Neiman');
    const [ogUrl] = useState(props?.ogUrl || 'https://www.rookierescuer.com');
    const [ogImage] = useState(props?.ogImage || 'img/image.png');
    return (
        <Helmet>
            <meta charset="utf-8" />
            <meta name="viewport" content={viewport} />
            <meta name="description" content={description} />
            <meta name="author" content={author} />
            <meta property="og:title" content={ogTitle} />
            <meta property="og:type" content={ogType} />
            <meta property="og:url" content={ogUrl} />
            <meta property="og:image" content={ogImage} />
            <title>{title}</title>
        </Helmet>
    );
}
