export default function Packages() {
    return (
        <section className="pb-20 relative block bg-gray-900">
            <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
                <div className="flex flex-wrap text-center justify-center">
                    <div className="w-full lg:w-6/12 px-4 bg-white rounded-lg p-4">
                        <img
                            alt="Sirens for The Cross"
                            src="/img/Sirens-for-The-Cross-logo-cross.png"
                            className="align-middle m-auto"
                        />
                        <p>
                            For more information about the real Firefighter Tom,
                            visit Sirens for the Cross Ministries at
                            <a
                                className="button bg-primary rounded text-white p-2 block m-auto mt-4 hover:bg-success w-1/3"
                                href="https://tommyneiman.com"
                            >
                                TommyNeiman.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <div id="contact"></div>
        </section>
    );
}
