import './text-wave.css'
export default function TextWave() {
    return (
        <svg viewBox="0 0 250 25">
            <path id="curve" d="M1,12 C110,35 100,0 200,18" />
            <text x="40">
                <textPath xlinkHref="#curve">WORKBOOK</textPath>
            </text>
            <text x="140">
                <textPath xlinkHref="#curve">for</textPath>
            </text>
        </svg>
    );
}
