import { useState } from 'react';

export default function LOWYBG(props) {
    const [bgColor] = useState(props?.bgColor || '');
    return (
        <>
            <h1
                className={`grilled-cheese text-error rookie-shadow ${bgColor}`}
                style={{
                    fontSize: '5.8rem',
                }}
            >
                Rookie Rescuer
                { props?.showTagline ?
                <p className="text-white text-xl soft-outline pb-5">
                    Learning about God and First Responder work through 'Real'
                    calls!
                </p>
            :
                null
            }
            </h1>


        </>
    );
}
