import Footer from './components/Footer/Footer';
import NavBar from './components/NavBar/NavBar';
import Home from './pages/Home';

function App() {
    const scrollToNestedElement = (id, offset=0) => {
        console.log(id)
        const element = window.document.getElementById(id);
        if (element) {
        //   element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        const offsetTop = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({
        top: offsetTop - offset,
        behavior: 'smooth',
        });
        }
      };


    return (
        <div className="text-gray-800 antialiased">
            <NavBar scrollToNestedElement={scrollToNestedElement}/>
            <Home />
            <Footer />
        </div>
    );
}

export default App;
