import './tommy-man.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import TextWave from '../../TextWave/TextWave';
import TextWave2 from '../../TextWave/TextWave2';
import { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Modal from 'react-modal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#root');

export default function TommyMan() {
    const [numPages, setNumPages] = useState();
    const [pageNumber] = useState(1);

    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <section className="pb-20 bg-secondary -mt-12 pt-10">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap items-center">
                    <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 drop-shadow-md rounded-lg bg-red-600">
                            <img
                                alt="Tommy Man"
                                src="img/front-cover-rookie-recuer-1200px.webp"
                                className="w-full align-middle rounded-t-lg -rotate-12"
                            />
                        </div>
                    </div>
                    <div className="w-full md:w-5/12 p-4 mr-auto ml-auto rounded-lg drop-shadow-md">
                        <div className="container text-center mr-auto ml-auto">
                            <TextWave />
                            <TextWave2 />
                        </div>
                        <ul className="text-warning text-2xl large-bullets">
                            <li>
                                <h2 className="uppercase inline p-2 text-white">
                                    12 Emergency Calls!
                                </h2>
                                <p className="antique-olive pl-8 text-white text-bold text-lg leading-relaxed mt-0 mb-4 text-gray-700">
                                    Kids will ride along with 'Firefighter Tom'
                                    on 12 exciting 'first responder' calls and
                                    learn valuable practical and spirtual lesson
                                    from each one. A memory verse, word
                                    definitions and insightful questions follow
                                    each call.
                                </p>
                            </li>

                            <li>
                                <h2 className="uppercase inline p-2 text-white">
                                    &#39;Family of God&#39; Section
                                </h2>
                                <p className="antique-olive pl-8 text-white text-bold text-lg leading-relaxed mt-0 mb-4 text-gray-700">
                                    Kids will read a clear and orderly
                                    presentation of the Gospel.
                                </p>
                            </li>

                            <li>
                                <h2 className="uppercase inline p-2 text-white">
                                    3 &#39;Field Trip&#39; visits
                                </h2>
                                <p className="antique-olive pl-8 text-white text-bold text-lg leading-relaxed mt-0 mb-4 text-gray-700">
                                    Kids take a &#39;Field Trip&#39; to a local
                                    fire station, an EMS Station, and a Fire
                                    Hydrant.
                                </p>
                            </li>
                        </ul>

                        <div className="container flex justify-center">
                            <button
                                id="openModalBtn"
                                className="bg-accent hover:bg-blue-700 text-dark text-lg shadow-lg font-bold p-4 rounded-2xl"
                                onClick={openModal}
                            >
                                Check Out the first chapter <br />{' '}
                                <strong className="text-error text-5xl grilled-cheese">
                                    FREE!!
                                </strong>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mx-auto px-4">
                <p className="text-pill bg-white p-4 text-center text-2xl mt-8 pill-outline">
                    Twelve action-packed calls with spacing for write-on answers
                    to practical and spirtual truths learned on each call. Clear
                    presention of the Gospel, educated field trips and more!
                </p>
            </div>

            <div className="flex flex-wrap mx-auto mt-16 px-4 justify-center">
                <ul className="flex flex-wrap grilled-cheese">
                    <li className="text-accent mr-5 text-shadow text-5xl -rotate-12">
                        Shark Bites!!
                    </li>
                    <li className="text-accent mr-5 text-shadow text-5xl -rotate-12">
                        Explosions!!
                    </li>
                    <li className="text-accent mr-5 text-shadow text-5xl -rotate-12">
                        Building Fires!!
                    </li>
                    <li className="text-accent mr-5 text-shadow text-5xl -rotate-12">
                        Medical Calls!!
                    </li>
                </ul>

                <p className="text-white mt-10 -mb-10 text-xl soft-outline">
                    Bookstores Everwhere - Bulk orders, free chapter read at{' '}
                    <a
                        className="text-accent"
                        href="https://rookierescuer.com/"
                    >
                        RookieRescuer.com
                    </a>
                </p>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Free Chapter"
            >
                <div className="scroll-component">
                    <div className="scroll-content">
                        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                            {subtitle}
                        </h2>
                        <button className='btn' onClick={closeModal}>
                            <img style={{
                                height: '20px',
                                position: 'absolute',
                                right: '1rem',
                                top: '20px'
                            }}
                                src='/img/close-x-svgrepo-com.svg'
                                alt="close modal"
                            />
                        </button>
                        <Document
                            file="/samples/Rookie-Rescuer-Free-Chapter-3.pdf"
                            onLoadSuccess={onDocumentLoadSuccess}
                        >
                            {Array.apply(null, Array(numPages))
                                .map((x, i) => i + 1)
                                .map((page) => (
                                    <Page key={`page-${page}`}pageNumber={page} />
                                ))}
                        </Document>
                        <p>
                            Page {pageNumber} of {numPages}
                        </p>
                    </div>
                </div>
            </Modal>
            <div id="order"></div>
        </section>
    );
}
