export default function Summary() {
    return (
        <section className="relative pt-20 bg-error pb-48 text-white font-bold -mt-12">
            <div className="container mx-auto px-4">
                <div className="items-center flex flex-wrap">
                    <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                        <h1 className="text-4xl">
                            &#39;Rookie Rescuer&#39; Summary
                        </h1>
                        <img
                            src="/img/tommy-man.png"
                            id="tommy"
                            alt="Tommy Man"
                            style={{
                                maxWidth: '200px',
                                position: 'relative',
                                top: '10px',
                            }}
                        />
                        <h2>
                            Your child will learn about God and First Responder
                            Work through Real Calls!
                        </h2>
                        <ul className="list">
                            <li>
                                12 units (calls) with spacing for write-in
                                answers to practical and spiritual questions
                            </li>
                            <li>&#39;Family of God&#39; section</li>
                            <li>
                                Three field trip visits: Fire station, EMS
                                station, Fire Hydrant (with instructions for
                                parents/teachers)
                            </li>
                            <li>Answer Sheets</li>
                        </ul>
                    </div>
                    <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                        <img alt="Certification Award" src="/img/cert.png" />

                        <h2 className="text-2xl m-2 bg-warning shadow rounded text-black text-center p-2">
                            Rookie Rescuer Certificate Page
                        </h2>

                        <ul className="list-disc">
                            <li>
                                Holds student interest (Parents/Teachers, too!)
                            </li>
                            <li>Biblically sound</li>
                            <li>
                                Spiritual questions promote great dialogue with
                                students
                            </li>
                            <li>
                                Creates awareness and respect for &#39;First
                                Responders&#39;
                            </li>
                            <li>
                                Clear explanation of the gospel - &#39;Family of
                                God&#39; Section
                            </li>
                            <li>
                                Calls presented in real but non-graphic ways
                            </li>
                            <li>
                                Memory verse and word definitions with each
                                chapter
                            </li>
                            <li>
                                Great addition to home school or Christian
                                school curriculum
                            </li>
                            <li>
                                Fun ...... will be a favorite of your student!
                            </li>
                            <li>
                                Shark Bites, Building Fires, Explosions, Wild
                                Fires and more!
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
