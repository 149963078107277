import './text-wave2.css'
export default function TextWave2() {
    return (
        <svg className='text-wave-2' viewBox="0 0 250 25">
            <path id="curve" d="M1,20 C110,25 100,0 200,15" />
            <text x="0">
                <textPath xlinkHref="#curve">
                    BOYS & GIRLS 2<sup>nd</sup> - 6<sup>th</sup> Grade
                </textPath>
            </text>

        </svg>
    );
}
