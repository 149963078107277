import Hero from '../components/Hero/Hero';
import MeetTommy from '../components/MeetTommy/MeetTommy';
import MetaData from '../components/MetaData/MetaData';
import Contact from '../components/Sections/Contact/Contact';
import Packages from '../components/Sections/Packages/Packages';
import Summary from '../components/Sections/Summary/Summary';
import TommyMan from '../components/Sections/TommyMan/TommyMan';
import WhatYouGet from '../components/Sections/WhatYouGet/WhatYouGet';

export default function Home() {
    return (
        <main>
            <MetaData
                title="Rookie Rescuer by Tommy Neiman"
                author="Tommy Neiman"
                description="A Christian-based workbook for children about fire and rescue personel."
                ogTitle="Rookie Rescuer by Tommy Neiman"
                ogUrl="https://www.rookierescuer.com"
                ogImage="img/image.png"
            />
            <Hero id="hero" />
            <TommyMan id="tommyman" />
            <MeetTommy id="meettommy" />
            <WhatYouGet id="order" />
            <Summary id="summary" />
            <Packages id="packages" />
            <Contact id="contact" />
        </main>
    );
}
