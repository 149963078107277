import './nav.css';

export default function NavBar({scrollToNestedElement}) {
    return (
        <nav className="sticky top-0 z-30 relative flex flex-wrap items-center justify-between px-2 py-3 bg-accent">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between lg:w-auto  px-4 lg:static lg:block lg:justify-start">
                    <a
                        className="grilled-cheese badge text-white bg-red-500 font-bold leading-relaxed inline-block mr-4 py-0 whitespace-nowrap uppercase"
                        href="/"
                    >
                        Rookie Rescuer
                    </a>
                    <button
                        className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                        type="button"
                    >
                        <span className="block relative w-6 h-px rounded-sm bg-white"></span>
                        <span className="block relative w-6 h-px rounded-sm bg-white mt-1"></span>
                        <span className="block relative w-6 h-px rounded-sm bg-white mt-1"></span>
                    </button>
                </div>
                <div
                    className="lg:flex flex-grow items-center"
                    id="example-navbar-warning"
                >
                    <ul className="flex flex-col lg:flex-row list-none ml-auto">
                        <li className="nav-item">
                            <button
                                id="go-to-order"
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white text-shadow-thin hover:opacity-75"
                                onClick={() => scrollToNestedElement('order', -630)}
                            >
                                Order
                            </button>
                        </li>
                        <li className="nav-item">
                            <a
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white text-shadow-thin hover:opacity-75"
                                href="https://www.tommyneiman.com/"
                                target="_new"
                            >
                                Tommy Neiman
                            </a>
                        </li>
                        <li className="nav-item">
                            <button
                                id="go-to-summary"
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white text-shadow-thin hover:opacity-75"
                                onClick={() => scrollToNestedElement('summary')}
                            >
                                Summary
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                id="go-to-contact"
                                className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white text-shadow-thin hover:opacity-75"
                                onClick={() => scrollToNestedElement('contact')}
                            >
                                Contact
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}
